body { background-color: #ffffff; }
body { color: var(--clr-6540); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Montserrat';
font-weight: 800;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
@media #{$large-up} {
font-size: 50px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 18px;

@media #{$large-up} {
font-size: 25px;

}
}
h4 {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.2;
font-size: 18px;

}
h5 {
font-family: 'Montserrat';
font-weight: 800;
font-style: normal;
line-height: 1.2;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Montserrat';
font-weight: 800;
font-style: normal;
line-height: 1.2;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 25px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
ol,ul {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 23;
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
summary {
font-family: 'Montserrat';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-6540);}
a:hover {color: var(--clr-6541);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-6541);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-6541);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-6541);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-6542);
color: #ffffff;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-6542);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-6542);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-6543);
color: #ffffff;
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-6543);
color: #ffffff;
 }
.MES7 {
background-color: var(--clr-6543);
color: #ffffff;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
 }
cite.MEC7{
color: #ffffff;
}
/* Light:8 */
.MES8 {
background-color: var(--clr-6544);
color: #ffffff;
 }
/* Light:9 */
.MES9 {
background-color: var(--clr-6544);
color: #ffffff;
 }
.MES9 {
background-color: var(--clr-6544);
color: #ffffff;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
 }
cite.MEC9{
color: #ffffff;
}
/* Main Menu:10 */
nav.me-Menu.MES10 {
& .menu-item.MEC10, & .menu-item.MEC10 > div.MEC10{ & > a.MEC10{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC10{color: var(--clr-6545);
}
 }
&.horizontal > .menu-item.MEC10 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC10 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC10 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dashed;
 }
&.horizontal > .menu-item.MEC10 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px 20px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC10:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-6546-flat);}}
&.vertical .menu-item.MEC10:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-6546-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC10{background-color: var(--clr-6546); &:hover {background-color: 4;}
}
& .menu-item.MEC10, & .menu-item.MEC10 > div.MEC10{ & > a.MEC10{color: var(--clr-6545);
text-transform: capitalize;
}
 &:hover > a.MEC10{color: 2;
}
 }

}
}
 }
/* Slider mask:11 */
.MES11 {
color: #ffffff;
padding: 150px 15px 15px 15px;

@media #{$large-up} {
padding: 0;

}
 }
.MES11 {
color: #ffffff;
padding: 150px 15px 15px 15px;

@media #{$large-up} {
padding: 0;

}
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
cite.MEC11{
color: #ffffff;
}
/* Home Slider:12 */
.MES12 {
& .slider-arrow {color: #ffffff;
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-6545);
} }
/* Hollow:13 */
.MES13 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-6545);
&:hover { color: #ffffff;}
padding: 10px 20px;

border-width: 3px;
border-style: solid;
border-color: var(--clr-6545);
&:hover { border-color: #ffffff; }
 }
/* Shade 1:14 */
.MES14 {
background-color: var(--clr-6547);
color: #ffffff;
 }
/* Shade 1:15 */
.MES15 {
background-color: var(--clr-6547);
color: #ffffff;
 }
.MES15 {
background-color: var(--clr-6547);
color: #ffffff;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* :16 */
.MES16 {
background-color: var(--clr-6540);
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://advancedshade.com.au/img/1853/18');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES16 {
background-color: var(--clr-6540);
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://advancedshade.com.au/img/1853/18');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: #ffffff;
 }
 }
cite.MEC16{
color: #ffffff;
}
/* light grey:17 */
.MES17 {
background-color: var(--clr-6545);
color: var(--clr-6540);
padding: 10px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6548) transparent;
 }
.MES17 {
background-color: var(--clr-6545);
color: var(--clr-6540);
padding: 10px;

@media #{$large-up} {
padding: 20px 30px;

}
border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6548) transparent;
 }
a.MEC17 { color: var(--clr-6540);
 }
cite.MEC17{
color: var(--clr-6540);
}
/* Category mask:18 */
.MES18 {
background-color: var(--clr-6549);
&:hover, &.hover { background-color: var(--clr-6546);}
color: #ffffff;
 }
.MES18 {
background-color: var(--clr-6549);
&:hover, &.hover { background-color: var(--clr-6546);}
color: #ffffff;
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: #ffffff;
 }
 }
cite.MEC18{
color: #ffffff;
}
/* Footer Menu:19 */
nav.me-Menu.MES19 {
& .menu-item.MEC19, & .menu-item.MEC19 > div.MEC19{ & > a.MEC19{color: var(--clr-6540);
text-transform: capitalize;
}
 &:hover > a.MEC19{color: var(--clr-6540);
}
 }
&.horizontal > .menu-item.MEC19 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC19 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC19 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC19 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Credit Panel:20 */
.MES20 {
color: var(--clr-6540);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES20 {
color: var(--clr-6540);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
cite.MEC20{
color: var(--clr-6540);
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Footer Panel:21 */
.MES21 {
background-color: var(--clr-6550);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES21 {
background-color: var(--clr-6550);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
a.MEC21 { color: var(--clr-6540);
&:hover { color: var(--clr-6540);}
 }
cite.MEC21{
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Black Panel:22 */
.MES22 {
background-color: var(--clr-6540);
 }
.MES22 {
background-color: var(--clr-6540);
 }
/* Popup Panel:24 */
.MES24 {
background-color: var(--clr-6543);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-6543);
color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 20px;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: #ffffff;
 }
 }
cite.MEC24{
color: #ffffff;
}
/* Home Blocks Panel:25 */
.MES25 {
background-color: var(--clr-6547);
&:hover, &.hover { background-color: var(--clr-6546);}
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 5px;
border-style: solid;
border-color: #ffffff;
 }
.MES25 {
background-color: var(--clr-6547);
&:hover, &.hover { background-color: var(--clr-6546);}
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 5px;
border-style: solid;
border-color: #ffffff;
 }
/* Responsive Menu:26 */
nav.responsive-menu {
.menu-item.MEC26{background-color: var(--clr-6541);}
& .menu-item.MEC26, & .menu-item.MEC26 > div.menu-item-wrap{ & > a.MEC26, & > i{color: #ffffff;
font-size: 13.86px;
@media #{$medium-up} {
font-size: 14px;
}text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC26 { border:0;
border-color: #ffffff;border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC26{background-color: 8;}
& .menu-item.MEC26, & .menu-item.MEC26 > div.menu-item-wrap{ & > a.MEC26, & > i{color: 2;
font-size: 13.86px;
@media #{$medium-up} {
font-size: 14px;
}text-transform: uppercase;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC26{background-color: 10;}
& .menu-item.MEC26, & .menu-item.MEC26 > div.menu-item-wrap{ & > a.MEC26, & > i{color: 2;
text-transform: uppercase;
text-align: left;
}
  }
}}

 }
/* light grey button:27 */
.MES27 {
background-color: var(--clr-6545);
&:hover, &.hover { background-color: var(--clr-6547);}
color: var(--clr-6540);
padding: 10px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6548) transparent;
 }
.MES27 {
background-color: var(--clr-6545);
&:hover, &.hover { background-color: var(--clr-6547);}
color: var(--clr-6540);
padding: 10px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-6548) transparent;
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: var(--clr-6540);
 }
&:hover { h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: #ffffff;} }
 }
a.MEC27 { color: var(--clr-6540);
&:hover { color: #ffffff;}
 }
cite.MEC27{
color: var(--clr-6540);
}
/* Title underline:32 */
.MES32 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-6542) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Hollow:28 */
.MES28 {
background-color: var(--clr-6544);
&:hover {background-color: var(--clr-6542);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 12.6px;
@media #{$medium-up} {
font-size: 16px;
};
border-radius: 10px;
padding: 5px 10px;

 }
/* white line:37 */
.MES37 {
padding: 5px 0;

& > hr {border-width: 3px 0 0 0;}
& > hr {border-color: #ffffff;}
& > hr {border-top-style: solid;}
 }
/* Map Panel:29 */
.MES29 {
background-color: var(--clr-6541);
&:hover {background-color: var(--clr-6542);}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 5px;

 }
/* Map Item Panel:30 */
.MES30 {
background-color: var(--clr-6550);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 10px;

 }
.MES30 {
background-color: var(--clr-6550);
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 10px;

h1.MEC30 { font-size: 22.5px; }
h1.MEC30 { @media #{$medium-up} { font-size: 28px; }; }
h1.MEC30 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC30 { font-size: 19.5px; }
h2.MEC30 { @media #{$medium-up} { font-size: 21px; }; }
h2.MEC30 { @media #{$large-up} { font-size: 24px; }; }
h3.MEC30 { font-size: 13.5px; }
h3.MEC30 { @media #{$medium-up} { font-size: 12.6px; }; }
h3.MEC30 { @media #{$large-up} { font-size: 20px; }; }
h4.MEC30 { font-size: 13.5px; }
h4.MEC30 { @media #{$medium-up} { font-size: 12.6px; }; }
h4.MEC30 { @media #{$large-up} { font-size: 14.4px; }; }
h5.MEC30 { font-size: 12.75px; }
h5.MEC30 { @media #{$medium-up} { font-size: 14px; }; }
h5.MEC30 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC30 { font-size: 12px; }
h6.MEC30 { @media #{$medium-up} { font-size: 12.6px; }; }
h6.MEC30 { @media #{$large-up} { font-size: 14.4px; }; }
 }
a.MEC30 { color: var(--clr-6540);
&:hover { color: var(--clr-6540);}
 }
cite.MEC30{
font-size: 12.6px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* header scroll:31 */
.MES31 {
background-color: var(--clr-6549);
color: #ffffff;
 }
.MES31 {
background-color: var(--clr-6549);
color: #ffffff;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
cite.MEC31{
color: #ffffff;
}
/* Title Panel:33 */
.MES33 {
background-color: var(--clr-6542);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
 }
.MES33 {
background-color: var(--clr-6542);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Share Button:34 */
.me-block.me-ShareButton .MES34{
figure.MEC34 { background-color: transparent;
&:hover{background-color: transparent;}
 }
figure.MEC34{ a { font-size: 20px;

color: var(--clr-6540);
 }
&:hover a {color: var(--clr-6543);}
 }

 }
/* Phone Number :35 */
.MES35 {
color: #ffffff;
 }
.MES35 {
color: #ffffff;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
 }
a.MEC35 { color: #ffffff;
 }
cite.MEC35{
color: #ffffff;
}
/* Green Panel:36 */
.MES36 {
background-color: var(--clr-6544);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
 }
.MES36 {
background-color: var(--clr-6544);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
cite.MEC36{
color: #ffffff;
}
/* Specs Panel:38 */
.MES38 {
background-color: var(--clr-6542);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
 }
.MES38 {
background-color: var(--clr-6542);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 15px;

}
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
cite.MEC38{
color: #ffffff;
}
/* Feature:39 */
.MES39 {
border-width: 5px;
border-style: solid;
border-color: var(--clr-6547);
 }
/* :40 */
.MES40 {
 }
